// Button Stylings for Cookies Noti bar

.cta{
	display: inline-block;
	background-color: $primary-color;
    color : $w; text-align: right;
	text-decoration: none; text-transform: uppercase;
	position: relative;	z-index: 1;
	@include padding-all(16px, 15px, 13px, 15px); margin-left: 20px;
	border: 0px; outline: none; min-width: 130px;
    font-weight: $light; font-size: 16px;
    font-family: $font-stack; height: 50px;
    cursor: pointer;

    &:after {
        width: 0px;
        height: 0px;
        border-left: transparent solid 20px;
        border-right: transparent solid 20px;
        border-bottom: 50px solid $primary-color;

        content: '';
        position: absolute;
        pointer-events: none;
        left: -20px; top: 0; z-index: 0;        
    }
	&:hover{
		color: $primary-color; 
		background-color: $w;
		text-decoration: none;

        &:after{
            border-bottom: 50px solid $w;
        }
	}	
}

.cta:focus, .underline-cta:focus, button:focus {
	outline: none; border:0px;
}


.cta2{
    display: block;
    background-color: $secondary-color;
    color : $w; text-align: left;
    text-decoration: none;
    position: relative;
    @include padding-all(16px, 55px, 13px, 15px); 
    border: 0px; outline: none; min-width: 130px;
    font-size: 20px;

    .fa{
        font-size:22px;
        color: $w; background: $primary-color;
        width: 31px; height: 32px; 
        line-height: 32px; 
        text-align: center;
        border-radius: 100%;
        @include vertical-align
        right: 15px;
    }

    &:hover{
        background: $primary-color; color: $w; text-decoration: none;

        .fa{                
            color: $primary-color; 
            background: $w;                
        }
    }        
}






