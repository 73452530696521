
.wrapper {
    position: relative;
    margin: 0 auto; 
}

.gbl{
	padding: 60px 0; 
}

div{
    position: relative;
}

.text-center{
	text-align: center;
	}

#window{
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    background: $w; 
}

section{
    width: 100%;
    display: block;
    float:left;
}

.inner{
    @include selfClearing;
    position: relative;
    margin: 0 auto;  
    max-width: 1200px; 
    width:94%;
}


.whiteBG{background-color: $w;}
.m-blueBG{background-color: $mblue;}
.l-greyBG{background-color: $lgrey;}

.text-left{text-align:left;}
.text-right{text-align:right;}
.text-center{text-align:center;}


.panelHeadings{
    text-align: center;
    padding-bottom: 40px;        
}


@media screen and (max-width:1039px){
   .gbl{padding: 20px 0;}
}


// Wookie bar widget
#cookieBar{
    @include transitions(opacity .3s, max-height .3s);
    background-color: $ddgrey;
    color: $w;
    text-align: center;
    font-size: 0.71em;
    z-index: 999;
    letter-spacing: 0.02em;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    
    .inner{border: 0;}
    
    p,a{
        color: rgba($w, 20%);
        display: inline-block;
        margin: 0;
    }

    P{margin-right: 15px;}

    a{ 
        margin: 0 5px;

        &:hover{
            color: $gbltxt-hover;
            text-decoration: none;
        }
    }
    a.accept{color: lighten($w, 20%);}    

    &.visible{
        padding: 10px;
        max-height: 100px;
        opacity: 1;
    }
}


// FADE IN SECTIONS 
html.js{
    section{
        &:not(:nth-of-type(1)){
            //@include transitions(opacity 2s, transform 1s);
            //transform: translateY(150px);
            //opacity:0;

            &.in-view{
                //opacity: 1;
                //transform: translateY(0);                
            } 
            &.vacancy-aplGrid{
                //transform: translateY(0) skewY(-2deg);
            }           
        }        
    }
}


.featured{
    .head{
        background-color: $mblue;
        color: $w; font-size: 20px;
        padding: 14px 10px;
        font-weight: $regular;
    }
    .img{
        overflow: hidden;
        
        img{
            width: 100%; 
            max-width: 100%;
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            -o-transition: all .5s;
            transition: all .5s; 
        }      
    }
    .info{
        background-color: $w;
        padding: 20px 15px 40px;        
        min-height: 130px;
        width: 100%;
        line-height: 23px;
    }

    a:hover{
        text-decoration: none;
        .img{
            img{
                -ms-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -webkit-transform: scale(1.2);
                -o-transform: scale(1.2);
                transform: scale(1.2);
            }
        }
    }
}


.contactInfo{
    @include padding(0px);
    @include margin (0px);
    list-style: none;
    display: inline-block;

    li{
        display: inline-block;
        border-right: 2px solid $secondary-color;
        @include padding-all(null, 20px, null, 20px);
        margin-left: -4px;

        &:first-child{padding-left: 0px;}
        &:last-child{border-right: 0px; padding-right: 0px;}

        .tel{
            color: $primary-color;
            font-size: 40px;

            &:hover{
                color: $secondary-color;
                text-decoration: none;
            }
        }
        .email{
            color: $secondary-color;
            font-size: 40px;

            &:hover{
                color: $primary-color;
                text-decoration: none;
            }
        }            
    }

    @media screen and (max-width: 800px){

        display: block;

        li{
            display: block;
            border-right: 0px;                
            margin-left: 0px;
            padding: 5px 0;
            
            .tel {font-size: 24px;}
            .email {font-size: 24px;}
        }
    } 
}


// Two Row Block 
.twoRow{ 
    padding: 0px;
    margin: 0px;
    list-style: none;
    @include selfClearing;
    

    li{
        //@include box-shadow(0 6px 30px -10px rgba(#000, 0.4) );
        width: 50%; float: left;
        width: calc(50% - (15px));
        margin-right: 30px;
        overflow: hidden;

        &:nth-child(2n+2)   {margin-right: 0px;}
        &:nth-of-type(2n+1) {clear: left;}
    }         
}


// Three Row Block 
.threeRow{ 
    padding: 0px;
    margin: 0px;
    list-style: none;
    @include selfClearing;
    

    li{
        width: 33.333%; float: left;
        width: calc(33.333% - 22.5px);
        margin-right: 30px;
        overflow: hidden;

        &:nth-child(3n+3)   {margin-right: 0px;}
        &:nth-of-type(3n+1) {clear: left;}
    }         
}


// Four Image Feature
.fourRow{    
    padding: 0px;
    margin: 0px;
    list-style: none;
    @include selfClearing;
    

    li{
        width: 25%; float: left;
        width: calc( 25% - (22.5px));
        margin-right: 30px;
        overflow: hidden;

        &:nth-child(4n+4) {margin-right: 0px;}
        &:nth-of-type(4n+1) {clear: left;}
    }         
}

// Five  Image Feature
.fiveRow{ 
    padding: 0px;
    margin: 0px;
    list-style: none;
    @include selfClearing;
    

    li{
        width: 20%; float: left;
        width: calc( 20% - (24px));
        margin-right: 30px;
        overflow: hidden;

        &:nth-child(5n+5) {margin-right: 0px;}
        &:nth-of-type(5n+1) {clear: left;}
    }         
}

.r-image{
    float: right; 
    margin:0 0 20px 20px;

    @include media(768px){
        float: none;
        margin:0 0 20px 0px;
    }
}

.l-image{
    float: left; 
    margin:0  20px 20px 0;

    @include media(768px){
        float: none;
        margin:0 0 20px 0px;
    }
}






