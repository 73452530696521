.siteHeader {    
    @include padding-all(15px, null, null, null);
    position: relative;

    .inner{
        max-width: 1425px;
    }

.header-logo{ 
    width: 40%;
	float: left;    
    @include padding-all(null, null, 10px, null); 

    a{
        display: inline-block;
    }
}

.header-box-r{
	width: 60%;
	float: right;
	text-align: right;

    .tel{
        color: $primary-color;
        font-size: 30px;
        display: block;

        a{
            color: $primary-color;
        }
    }
    .email{
        color: $secondary-color;
        font-size: 30px;
        display: block;
    }

    @media screen and (max-width: 1035px){
        
        .tel{font-size: 34px;}
        .email{font-size: 34px;}       
    }

    @media screen and (max-width: 768px){
        .tel{font-size: 20px;}
        .email{font-size: 20px;}
    }
    @media screen and (max-width: 550px){        
        .email{font-size: 16px;}
    }
 }

@media screen and (max-width:1035px){
    padding-top: 20px;

       
} 

}


