
// BANNER SLIDER
.request-quote{
    //@include vertical-align; z-index: 9; right: 100px;
    width: 100%; 
    @include padding(20px);             
    color: $w;
    font-size: $bodyMobile;
    background: rgba(20,61,96,0.8);            
    font-family: $font-stack2;
    overflow: hidden;
    margin-bottom: 30px;

    header{
        pointer-events: none;
        h2{
            font-size: 22px;
        }
        p{
            font-weight:$light; 
            line-height: 20px; 
            font-size: $smallText;
        }
    }            

    footer{
        .form-group {
            margin-bottom: 10px;

            ::-webkit-input-placeholder { color: $secondary-color; font-style: italic;}
            ::-moz-placeholder { color: $secondary-color;;}
            :-ms-input-placeholder { color: $secondary-color;;}
            :-moz-placeholder { color: $secondary-color;;}

            .form-control {
                width: 100%;
                border: 0px;
                outline: none;
                min-height: 40px;
                padding: 5px 10px;
                color: $secondary-color;
            }
        }

        button.cta{
            float:right; text-align: center;
            min-width: 180px;

            &:after {
                border-bottom: 50px solid $primary-color;
            }

            &:hover{                    
                background-color: $w;                    

                &:after{
                    border-bottom: 50px solid $w;
                }
            }
        }                
    }

    
}