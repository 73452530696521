header.siteHeader{
    >.inner{position: static;}

    // GLOBAL NAVIGATION
    nav.nav{
        width: 100%;
        font-weight: $light;
        background-color: $mblue;
        @includ selfClearing;

        a{text-decoration: none; display: inline-block;}

        ul.l0{
            width: auto;            
            @include margin(0px);
            @include padding(0px);            
        }

        .listless{
            li{
                list-style: none;
                @include margin(0px);
                display: block;
                position: relative;
                float: left;
                height: auto;
                text-transform: uppercase;                

                &:last-child{border-right: 0px;} 

                &:after{
                    @include vertical-align;
                    background-color:#0e57a7; width: 2px; height: 44px;
                    right: 0; content:''; z-index:0;
                }               
            }
        }
    }

    // Main Navigation
    nav#mainNav{
        ul.l0{
            display: none;

            li.l0 {                

                a{
                    @include transitions(padding .3s, box-shadow .3s);
                    color: $w; position: relative;              
                    font-size: 20px;
                    font-weight: $light;                
                    @include padding-all(20px, 30px, 20px, 30px);

                    @media screen and (max-width: 1525px) {
                        font-size: 20px; height: 64px;
                        @include padding-all(20px, 30px, 20px, 30px);
                    }
                    @media screen and (max-width: 1240px) {
                        font-size: 16px; height: 60px;
                        @include padding-all(20px, 25px, 20px, 26px);
                    }                
                }

                &:hover .childMenu{
                    visibility: visible;
                    opacity: 1;
                }
            }

            li.l0.active a, li.l0 a:hover {
                background-color: $ddblue;
                color: $w;z-index:1;
            }            
        }

        @include media(1035px) {
            
        }
    }
}

// Scroll header stick
.childMenu{
    position: absolute; 
    left:0; top: 100%; 
    z-index:9; width: 100%;
    background-color: $ddblue;
    @include margin(0px);
    @include padding(0px);
    visibility: hidden;
    opacity: 0;  

    li{
        width: 100%;
        border-bottom: 1px solid #e19f0d;
        border-right: 0px !important;

        a{
            font-size: 16px !important;               
            padding:   12px 0 12px 20px !important; 
            display:   block !important;
            color: $b !important; 
            height: auto !important;

            .fa-angle-right{
                position: absolute; font-size: 22px;
                right: 20px; top: 10px;
            }

            &:hover{color: $w !important;}
        }

        &:hover .sub-childMenu{
            visibility: visible;
            opacity: 1;  
        }
    }    

    .sub-childMenu{
        left: 100%; top: 0px;
        position: absolute; 
        z-index:9; width: 100%;
        background-color: $mblue;
        @include margin(0px);
        @include padding(0px);
        @include box-shadow(0 6px 30px -10px rgba(#000, 0.4) );
        visibility: hidden;
        opacity: 0;  
    }    
}

@media screen and (max-width: 1035px) {
    .childMenu{
        top: 0px;
        position: relative;
        visibility: visible;
        opacity: 1;
        background-color: $mblue;

        li{
            border-bottom: inherit;

            a{
                font-size: 18px !important;               
                padding: 20px !important;                 
                color: $w !important; 

                .fa-angle-right{
                    display: none;
                }
                
            }
        }
    }
}

.mobile-nav {
    .mm-panels {
        .mm-panel{
            width: 100%;
        }
    }
}


// Mobile Menu specific styles
header.siteHeader {
    .toggle{        
        width: auto;
        padding: 20px;
        color: $w;
        float: right;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: $regular;
        font-size: 20px;
        z-index: 99;
        background-color: $primary-color;
    }
    
    @media screen and (min-width: 1035px) {
        .toggle{
            display: none;
        }

    }
}

$bar-width: 30px;
$bar-height: 3px;
$bar-spacing: 8px;
$bar-color: $w;

.hamburger-menu {
    margin: auto;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
    float: right;
    margin-left: 10px;
    margin-top: 3px;
    
    .bar,
    .bar:after,
    .bar:before {
        width: $bar-width;
        height: $bar-height;
    }

    .bar {
        position: relative;
        transform: translateY($bar-spacing);
        background: $bar-color;
        transition: all 0ms 300ms;
    
        &::before{
            content: "";
            position: absolute;
            left: 0;
            bottom: $bar-spacing;
            background: $bar-color;
            transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }

        &::after{
            content: "";
            position: absolute;
            left: 0;
            top: $bar-spacing;
            background: $bar-color;
            transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }
    }

    .mm-opening & .bar {
        background: rgba(255, 255, 255, 0); 
    }

    .mm-opening & .bar::after {
        top: 0;
        transform: rotate(45deg);
        transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }

    .mm-opening & .bar::before {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }
}


