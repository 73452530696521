
// Social Media Block

.social-media{    
    ul{
        @include padding(0px);
        @include margin(0px);
        list-style: none;        

        li{ 
            float: left;
            list-style-type: none;
            margin-right: 5px;

            &:lasr-child{margin-right: 0px;}

            a{
                color: $w;                
                @include padding-all(10px, null, 10px, null);
                display: block; text-align: center; position: relative;
                width: 38px; height: 38px; border-radius: 100%; border: 2px solid $w;

                .fa{
                    @include middle;
                    font-size: 20px;
                }

                &:hover{
                    text-decoration: none;
                    background-color: $primary-color;
                }
            }            
        }
    }

    .twitter{

    }

    .twitter { background: $tw; }
    .facebook { background: $fb; }
    .instagram { background: $inst; }
    .linkedin { background: $li; }
}