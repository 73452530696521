
// Top Search Block
.topSearch{
    background-color: $secondary-color;
    color: $w; position: relative;
    z-index: 88; 

    @media screen and (max-width: 1035px){
        display: none;
    }   

    .inner{
        max-width: 1425px;
    }    

    .search{
        //overflow: hidden;
        //width: 70px;
        //cursor: pointer;
        position: absolute; 
        right: 0; top: -58px; 
        width: 450px; height: 58px; 
        line-height: 60px; 
        text-align: center; 
        z-index: 9;         
        background-color: $secondary-color;

        .searchBtn{
            cursor: pointer;
            height: 58px;
            @include transitions(color .2s);  
            display: none;          

            &::before{
                content: '\f002'; 
                font-family:'FontAwesome';
                font-size: 36px;
                color: $w;
            }
        }

        // Search Bar Inactive
        .searchBox{
            //right: 100%;
            //pointer-events: none;
            position: absolute;
            right: 0;
            top: 0px;
            width: 450px;
            height: 100%;
            overflow: hidden;            
            z-index: 9;
            height: 58px;            

            .search-form{
                display: inline-block;
                position: relative;

                .txtsearch {
                    background-color: $w;            
                    color: $secondary-color;
                    @include padding-all(15px, 150px, 14px, 10px);
                    letter-spacing: $font-space1;

                    width: 100%;
                    height: 100%;                
                    border:0;
                    outline: 0;            
                    display: block;
                    float: left;  
                    height: 50px; 
                }
                
                .cta {
                    position: absolute;
                    top: 4px; right: 4px;                    
                    z-index: 9;
                    padding-top: 14px;
                    padding-bottom: 15px;
                    text-align: center;
                    font-size: 18px;

                    &:hover{
                        color: $w; 
                        background-color: $primary-color;                    

                        &:after{
                            border-bottom: 50px solid $primary-color;
                        }
                    }
                }

                ::-webkit-input-placeholder { color: $secondary-color; font-style: italic;}
                ::-moz-placeholder { color: $secondary-color;;}
                :-ms-input-placeholder { color: $secondary-color;;}
                :-moz-placeholder { color: $secondary-color;;}
            }            

            // Form element is hidden via offser left
            form{
                //left: 100%;
                @include transitions(left .4s ease-out);
                position: absolute;                
                width: 100%;
                left: 0;
                font-size: $medium;
                padding: 4px;
                background-color: $secondary-color;
            }            
        }

        // Search Bar Active
        &.searchActive{
            overflow: visible;

            .searchBox{
                pointer-events: auto;
                form{
                    left: 0;                            
                }
            } 

            .searchBtn{                
                &::before{
                    content: '\f00d';
                }
            }
        } 

        @include media (1425px){
            width: 370px;

            .searchBox{                
                width: 370px;                           
            }
        }

        @include media (1160px){
            overflow: hidden;
            width: 70px;
            cursor: pointer;

            .searchBtn{                
                display: block;               
            }

            // Search Bar Inactive
            .searchBox{
                right: 100%;
                pointer-events: none; 
                width: 450px;
                                
                form{
                    left: 100%;
                }            
            }
        }
    }

    @include media(1035px){

        padding: 20px 0px !important;
        background-color: $trans;

        .search-form{
            background-color: $w;
            position: relative;

            .txtsearch {
                    background-color: $w;            
                    color: $secondary-color;
                    @include padding-all(15px, 150px, 14px, 10px);
                    letter-spacing: $font-space1;

                    width: 100%;
                    height: 100%;                
                    border:0;
                    outline: 0;            
                    display: block;
                    float: left;  
                    height: 50px; 
                }
                
                .cta {
                    position: absolute;
                    top: 0; right: 0px;                    
                    z-index: 9;
                    padding-top: 14px;
                    padding-bottom: 15px;
                    text-align: center;
                    font-size: 18px;
                    background-color: $secondary-color;

                    &:after{
                        border-bottom: 50px solid $secondary-color;
                    }

                    &:hover{
                        color: $w; 
                        background-color: $primary-color;                    

                        &:after{
                            border-bottom: 50px solid $primary-color;
                        }
                    }
                }
        }
    }


}