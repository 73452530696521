/*--- Navigation Css ---*/

.navbarBlock {
	text-align:left; width: 100%; height:auto;
	position:relative; background-color: $primary-color;
	z-index: 10;

	@media screen and (max-width: 1035px){
		display: none;
	}

	.inner{
		position:static;
		max-width: 1425px;
	}

	.dsk-navbar {
		@include padding(0px);
		@include margin(0px);
		display: inline-block;

		li{
            list-style: none;            
            display: inline-block;
            margin:0 0 0 -2px;
            text-transform: uppercase;

            &:last-child {
	            a{
	            	&:after{
	                    display: none;
	                }
	            }	                
	        }

            a{
                color: $w; position: relative;              
                display: block;
                font-weight: $light;                
                @include padding-all(20px, 30px, 20px, 30px);

                &:after{
	                @include vertical-align;
	                background-color:#0e57a7; width: 2px; height: 44px;
	                right: -2px; content:''; z-index:0;
	            }

	            &:hover {
		            background-color: $ddblue;
		            color: $w; z-index:1;
		            text-decoration: none;
		        }

		        @media screen and (max-width: 1100px){
		        	@include padding-all(20px, 25px, 20px, 25px);
		        }	

		        @include media (1425px){
		        	@include padding(20px);
		        }
		        @include media (1225px){
		        	@include padding-all(20px, 15px, 20px, 15px);
		        }	
		        @include media (1160px){
		        	@include padding-all(20px, 30px, 20px, 30px);
		        }		                
            }

            &.active {
				a{
		         	background-color: $ddblue;
		            color: $w; z-index:1;
		            text-decoration: none;
		        }
			} 

			&:hover {
				.dropdownGrid{
					display: block;
				}
			}

			&.dropdown{
				position: relative;

				.dropdownGrid{
					min-width: 200px; 
					padding: 10px;
				}
				.Submenu{
					display: block; 
					width: 100%;
				}
			}

			&:hover > a{
				background-color: $ddblue;
		    	color: $w;
			}
        } 

        @media screen and (max-width: 1100px){

        }       
	}

	.dropdownGrid{
		display: none; width: 100%; 
		position: absolute; padding: 50px 0 30px; 
		top: 100%; left: 0px; 
		background-color: $ddblue;
		z-index: 99;

		hr{
			margin: 5px 0 30px;
			border-color: #1565ae;
		}

		.Submenu{
			display: inline-block; width: 16.333%; 
			padding: 0 5px; margin: 0; 
			color: #fff; vertical-align: top;

			ul{
				display: block !important; 
				height: auto; 
				@include padding(0px);
				@include margin-all(15px, 0px, 15px, 0);

				li{
					display: block;
					margin-left:0px;				

					a{
						color:#fff; padding:6px 0px; 
						font-size: 13px; float: none;
						height: auto;
						font-weight: $light;
						line-height: normal; font-weight: $light;
						text-transform: none;

						&:after{
			                display: none;
			            }

						&:hover{
							color: $gbltxt-hover;
						}
					}

					&.active{
						a{
							color: $gbltxt-hover;
						}	
					}
				}
			}

			h6{
				font-size: 14px;


				a{
			    	color: #fff ; padding: 0px; 
			    	float: none ; height: auto; 
			    	line-height: normal ;
			    	font-weight: $regular;
			    	
			    	&:after{
		                display: none;
		            }

			    	&:hover{
			    		color:$gbltxt-hover !important;
			    	}
			    }
			}
		}
	}
}
/*--- End ---*/



