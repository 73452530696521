*, *::before, *::after{
    @include box-sizing;
}

html.mm-blocking{
    overflow: visible;
}

body,
html,
header,
footer,
section,
div,
article,
main,
nav{
    width: 100%;
    margin: 0;
    padding: 0;
}

input{max-width: 100%;}
blockquote{margin: 0;}


img{
    display: block;
    height: auto;
    max-width: 100%;
}
  

html, body {    
    font-size: $body;
    font-family: $font-stack;    
    font-weight: $regular;
    color: $text-color;
    overflow: auto;
    @include padding(0px);
    @include margin(0px);

    @media screen and (max-width: 768px) {
        font-size: $bodyMobile;
    }
}

body {
    overflow-x: hidden; 
}


// Heading sizes 

h1, h2, h3, h4, h5, h6 {    
    font-family: $font-stack;
    font-weight: $regular;
    @include margin(0px); 
    @include padding(0px);
    position: relative;
}

h1 { font-size: 40px;}
h2 { font-size: 40px;}

h3 { font-size: 30px; font-weight: $medium; color: $primary-color;}

h4 { font-size: 24px;}

h5 { font-size: 20px; color: $primary-color;}
h6 { font-size: 20px;}

@include media(768px){
    h1 { font-size: 44px;}
    h2 { font-size: 36px;}
    h3 { font-size: 24px; font-weight: $medium; color: $primary-color}
    h4 { font-size: 20px;}
    h5 { font-size: 18px;} 
}

a{
    color:$secondary-color;
    text-decoration:none;

    &:hover{
        text-decoration:underline;
        color: $gbltxt-hover;
    }
}


// Grid Columns Style 

.column, .columns {
    width:100%;
    float:left;
    box-sizing:border-box;
}

.col-1  {width: 8.333%;}
.col-2  {width: 16.666%;}
.col-3  {width: 25%;}
.col-4  {width: 33.333%;}
.col-5  {width: 41.666667%;}
.col-6  {width: 50%;}
.col-7  {width: 58.333333%;}
.col-8  {width: 66.666667%;}
.col-9  {width: 75%;}
.col-10 {width: 83.333333%;}
.col-11 {width: 91.666667%;}
.col-12 {width:100%;}

@include media (768px){

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11{
        width:100%;
    }    
}


