
.bannerBlock{
    position: relative;

    @media screen and (max-width: 970px){
        padding-bottom: 60px;
    }

    .request-quote{
        @include vertical-align; z-index: 9; right: 100px;
        max-width: 450px;
        margin-bottom: 0px;

        @media screen and (max-width: 1120px) {
        max-width: 400px;           
    }

    @media screen and (max-width: 970px) {
        @include no-vertical-align; right: inherit; top: inherit;
        max-width: inherit; width: 100%; 
        background: $primary-color; 
        margin: auto; text-align: center;
        @include padding(0px);

        header{
            cursor: pointer; pointer-events: inherit;
            @include padding-all(18px, 25px, 18px, 25px);

            p{
                display: none;
            }
        }
        footer{
            @include padding(10px);
            display: none;
            overflow: hidden;

            button.cta{              
                background-color: $secondary-color;

                &:after {
                    border-bottom: 48px solid $secondary-color;
                }

                &:hover{                    
                    background-color: $w;                    

                    &:after{
                        border-bottom: 48px solid $w;
                    }
                }
            }
        }
    }
    }
}

.banner{
    @include transitions(height .3s, min-height .3s);
    //height: 100vh;    
    height: 510px;
    padding: 0;
    position: relative;
    border-bottom: 10px solid $dblue;
    
    // Background cover
    &,.slide, .img{@include bgCover;}    

    .img{height: 100%;}

    @media screen and (max-width: 970px) {
        max-height: 300px;
        border-bottom: 0px solid $dblue;
    }
}

// BANNER SLIDER
.banner{

    &.inner-banner{

        .slideSet{
            .billboard{
                background: $trans;                

                .cta2{
                    display: inline-block;
                    padding: 15px;
                    line-height: normal;
                    font-size: 18px;
                    margin-bottom: 30px;
                }

                @media screen and (max-width: 970px) {
                    @include vertical-align;                    
                }
            }
        }

        @media screen and (max-width: 970px){

            .flickity-page-dots{
                bottom: -48px;                
            }
        }
    }

    .slideSet{
        height: 100%; position: absolute;
        left: 0; bottom: 0; top: 0; right: 0;            

        .billboard{
            @include vertical-align; z-index: 9; left: 100px;
            max-width: 780px; width: 100%; 
            @include padding(20px); 
            text-align: left; 
            color: $w;
            font-size: $bodyMobile;
            background: rgba(20,61,96,0.5);
            line-height: 64px; 

            .header-title{
                font-size: 40px;
                font-family: $font-stack;
                font-weight: $light;
                line-height: normal;

                strong{
                    font-weight: $medium;
                }
            }                        
        }        

        @media screen and (max-width: 1445px) {
            .billboard{                
                max-width: 450px;
                line-height: 40px;

                .header-title{
                    font-size: 30px;                    
                }                            
            }            
        } 
        @media screen and (max-width: 1120px) {
            .billboard{                
                max-width: 350px;                           
            }            
        } 
        @media screen and (max-width: 970px) {
            .billboard{
                @include no-vertical-align; 
                left: 0px; bottom: 0px; top: inherit;
                position: absolute;    
                max-width: 100%; width: 100%;                           
            }
        }
    }    
    
    .slideSet, .slide{
        height: 100%;
    }

    .flickity-prev-next-button{
        width: 72px;
        height: 72px;
        border-radius: 0;
        background: rgba(20,61,96,0.8);
        @include padding(0px);

        svg{
            display: none !important;
        }   

        @media screen and (max-width: 970px) {            
            -webkit-transform: inherit;
            transform: inherit;
            top: 100%; width: 61px; height: 61px;
            z-index: 99;
            background: $secondary-color;

            &:disabled {
                opacity: 0.6;
            }
        }
    }

    .flickity-prev-next-button.previous { 
        left: 0px;

        &:after{
            content: "\f104"; font-family: 'FontAwesome';
            position: absolute;
            @include middle; left: 43%;
            color: $w; font-size: 60px;
        }
    }

    .flickity-prev-next-button.next{ 
        right: 0px;

        &:after{
            content: "\f105"; font-family: 'FontAwesome';
            position: absolute;
            @include middle; right: 30%;
            color: $w; font-size: 60px;
        }
    }  

    .flickity-page-dots{
        width: auto;
        bottom: 25px;        
        left: 100px;

        .dot {
            width: 15px;
            height: 15px;
            margin: 0 3px;
            background: $trans;            
            opacity: 1;            
            border: 2px solid $w;

            &.is-selected {                
                background: $w;
            }
        }

        @media screen and (max-width: 970px){
            bottom: -99px;
            left: 50%;

            .dot {                
                background: $trans;            
                opacity: 1;            
                border: 2px solid $secondary-color;

                &.is-selected {                
                    background: $secondary-color;
                }
            }
        }
    }       
}


// INEER BANNER
.inner-hero{
    height: 550px;
    padding: 0;
    position: relative;
    background-color: $b;
    
    // Background cover
    .img{
        @include bgCover; 
        height: 100%;
        position: relative;

        .header-title{
            font-family: $font-stack;            
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
            color: $w;
            font-size: 40px;
            @include vertical-align;
            font-weight: $bold;
            left: 100px;
            text-transform: uppercase;
            letter-spacing: $font-space2;

            @include media(600px){
                @include middle;
                width: 100%;
                text-align: center;                
            }
        }

        h1{
            font-family: $font-stack;            
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
            color: $w;
            @include vertical-align;
            font-weight: $bold;
            left: 100px;
            text-transform: uppercase;
            letter-spacing: $font-space2;

            @include media(600px){
                @include middle;
                width: 100%;
                text-align: center;                
            }
        } 
    }
    
    @media screen and (max-width: 768px) {
        max-height: 300px;
    }
}




