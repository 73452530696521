
.footer {
    
    color: $w; 
    font-weight: $light;
    float: left;

    .inner{
        max-width: 1425px;
    }

    .footNav{
        font-weight: $light;
        background-color: $primary-color;
        @includ selfClearing;        

        .listless{
            width: auto; float: left;
            @include margin(0px);
            @include padding(0px);
            overflow: hidden;

            li{
                list-style: none;
                @include margin(0px);
                display: block;
                position: relative;
                float: left;
                height: auto;
                text-transform: uppercase;
                

                &:last-child {
                    &:after{
                        display: none;
                    }
                } 

                &:after{
                    @include vertical-align;
                    background-color:#0e57a7; width: 2px; height: 44px;
                    right: 0; content:''; z-index:0;
                } 

                a{                    
                    color: $w; position: relative;                    
                    font-weight: $light;                
                    @include padding-all(18px, 20px, 18px, 20px);
                    display: block;                   

                    &.active a, &:hover {
                        background-color: $ddblue;
                        color: $w;z-index:1;
                        text-decoration: none;
                    }               
                }              
            }
        }

        .social-media{
            float: right;
            width: auto;
            padding-top: 10px;
        }

        @media screen and (max-width: 950px){
            text-align: center;

            .listless{
                float: none;
                display:inline-block;
                margin-bottom: 5px;

                li{
                    float: none;
                    display: inline-block;
                    margin-left: -4px;
                }
            }

            .social-media{
                float: none;
                overflow: hidden;
                text-align: center;
                padding: 10px 0;

                ul{
                    display: inline-block;
                }
            }
        }
    }

    .footLogo{
        @include padding-all(40px, null, 40px, null);
        background-color: $w;
        @includ selfClearing;

        .leftBlock{
            float: left;
            width: 27%;            
            color: $b;

            p:last-child{margin-bottom: 0px;}

            p{
                font-size: $bodyMobile;
                font-weight: $medium;
            }
        }

        .partners{
            float: right;
            width: 70%;
            padding-top: 10px;
            
            ul {
                width: 100%;
                text-align: center;
                @include padding(0px);
                @include margin(0px); 

                 li {
                    float: none;
                    display: inline-block;
                    padding: 0 20px;
                    vertical-align: middle;
                    max-width: 250px;                    
                }              
            }
        }

        @media screen and (max-width: 1305px){
            @include padding-all(10px, null, 30px, null);

            .leftBlock{
                float: none;
                width: 100%;
                text-align: center;
                padding-bottom: 10px;

                img{
                    display: inline-block;
                } 
            }
            .partners{
                float: none;
                width: 100%;
                padding-top: 10px;
                
                ul {
                     li {                        
                        padding: 10px;                        
                    }              
                }
            }
        }
    }    

    .footBar{
        background-color: $dblue;
        @include padding-all(20px, null, 20px, null);
        color: $w;
        font-weight: $light;
        font-size: $bodyMobile;
        text-align: center;
        line-height: 24px;
    }
    
}


    