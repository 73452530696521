// Team Block

.teamBlock{
    @include padding-all(70px, null, 40px, null);

    ul{
        @include padding(0);
        @include margin (0);
        list-style: none;

        li{
            .content{                

                .contactInfo{

                    font-weight: $regular;

                    li{                        
                        .tel  { font-size: 20px; }
                        .email{ font-size: 20px; }
                    }

                    @media screen and (max-width: 800px){ 

                        margin-bottom: 20px;                       

                        li{ 
                            margin-bottom: 0px;

                            .tel  {font-size: 18px;}
                            .email{font-size: 18px;}
                        }
                    } 
                }
            }
        }
    }
}


