
// General Content Page Style
.intro{
    @include padding-all(50px, null, 50px, null);    
    text-align: center;    

    .inner img{
        display: inline-block;
    }    

    .panelHeadings{
        padding-bottom: 10px;

        h1{
            font-weight: $medium;            
        }

        h2{
            font-weight: $medium;
            span{
                font-weight: $light;
            }
        }
    }

    p{
        line-height: 24px;

        &:last-child{
            margin-bottom: 0px; 
            padding-bottom: 0px;
        }
    }

    h6{
        line-height: 30px;

        &:last-child{
            margin-bottom: 0px; 
            padding-bottom: 0px;
        }
    }
}

.panelHeadings2{

    h1{
        font-weight: $medium;
        margin-bottom: 30px;        
    }
    h2{
        font-weight: $medium;
        margin-bottom: 30px;        
    }
    h3{
        color: $b;
        margin-bottom: 30px; 
    }
    h5{
        margin-bottom: 30px;        
    }
}

.pageHeader{
    text-align: left;
    @include padding-all(50px, null, 50px, null);

    .panelHeadings{
        text-align: left; 
        float: left;
        width: auto;
        border-right: 2px solid $b;
        padding-right: 30px;
        padding-bottom: 0px;

        h1{
            font-weight: $bold;
            color: $primary-color;
            padding-bottom: 0px;
        }
    }

    p{
        line-height: 30px; 
        font-size: 24px;
        padding-left: 30px;
        display: table;
        border-left: 2px solid $b;
        position: relative; left: -2px;
        margin: 0px;
    }

    .inner{
        max-width: 1425px;
    }

    @media screen and (max-width: 1035px){
        .panelHeadings{
            text-align: center; 
            float: none;
            width: 100%;
            border-right: 0px;
            padding-right: 0px;
            padding-bottom: 30px;            
        }
        p{
            text-align: center; 
            padding-left: 0px;
            display: block;
            border-left: 0;
            position: relative; left: inherit;
           
        }
    }
}


// Products Block Style
.productBlock{
    @include padding-all(60px, null, 30px, null); 
    width: 100%;
    
    .featured{
        .cta{
            float:right;
            margin-top: -25px; 
            
            &:hover{
                color: $w; 
                background-color: $mblue;
                text-decoration: none;

                &:after{
                    border-bottom: 50px solid $mblue;
                }            
            }           
        }                
    }

    ul.fourRow{

        li{            
            margin-bottom: 30px; 
            position: relative;
            overflow: inherit;


            &:hover{
                .img{
                    img{
                        -ms-transform: scale(1.2);
                        -moz-transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -o-transform: scale(1.2);
                        transform: scale(1.2);
                    }
                }                
            }
                        
        }        

        @media screen and (max-width: 1150px){

            li{
                width: 33.333%; float: left;
                width: calc( 33.333% - (20px));                

                &:nth-child(4n+4) {margin-right: 30px;}
                &:nth-of-type(4n+1) {clear: inherit;}

                &:nth-child(3n+3) {margin-right: 0px;}
                &:nth-of-type(3n+1) {clear: left;}
            }            
        } 

        @media screen and (max-width: 992px){

            li{
                width: 50%; float: left;
                width: calc( 50% - (15px));                

                &:nth-child(4n+4) {margin-right: 30px;}
                &:nth-of-type(4n+1) {clear: inherit;}

                &:nth-child(3n+3) {margin-right: 30px;}
                &:nth-of-type(3n+1) {clear: inherit;}

                &:nth-child(2n+2) {margin-right: 0px;}
                &:nth-of-type(2n+1) {clear: left;}
            }                       
        }

        @media screen and (max-width: 600px){

            li{
                width: 100%; float: left;
                width: calc( 100% - (0px));                

                &:nth-child(4n+4) {margin-right: 0px;}
                &:nth-of-type(4n+1) {clear: inherit;}

                &:nth-child(3n+3) {margin-right: 0px;}
                &:nth-of-type(3n+1) {clear: inherit;}

                &:nth-child(2n+2) {margin-right: 0px;}
                &:nth-of-type(2n+1) {clear: inherit;}
            }            
        }
    }
}

.fullBio{
    overflow: hidden;                        
    background-color: $w;
    max-width: 1000px;
    width: 100%; 
    display: none;
    padding: 40px 20px 20px 20px;

    h3{
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 3px solid $mblue;
    }

    .details{
        position: relative;

        .cta{
            display: inline-block; 
            text-align: center; 
            padding: 16px 20px;
            margin-top: 10px;
            
            &:hover{
                color: $w; 
                background-color: $secondary-color;
                text-decoration: none;

                &:after{
                    border-bottom: 50px solid $secondary-color;
                }
            }   
        }
    }
    .image{
        text-align: center;
        margin-bottom: 20px;

        img{
            display: inline-block;
        }
    }

    .fancybox-close-small {

        &:after{            
            top: 5px;
            right: 5px;
            width: 36px;
            height: 36px;
            font-size: 36px; 
            line-height: 36px;            
        }
    }
}

// Products Block Title
.blockHeadings{
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;    

    h2{
        border-bottom: 4px solid $ddblue;
        padding-bottom: 20px; min-height: 55px;
        line-height: 32px; font-weight: $medium;
    }

    h3{
        margin-bottom: 20px;
        font-weight: $medium;
        font-size: 24px;
        color: $secondary-color;
    }

    .cta2{
        position: absolute; right:0px; bottom:5px; width: auto;
        background-color: $ddblue; display: inline-block;
        color: $w; font-size: 16px;
        text-decoration: none; cursor: pointer;

        &:after {
            width: 0px;
            height: 0px;
            border-left: transparent solid 20px;
            border-right: transparent solid 20px;
            border-bottom: 48px solid $ddblue;

            content: '';
            position: absolute;
            pointer-events: none;
            left: -20px; top: 0; z-index: 0;        
        }        

        &:hover{
            
            &:after {                
                border-bottom: 48px solid $primary-color;              
            }

            .fa{                
                color: $primary-color; 
                background: $w;                
            }
        }

        @media screen and (max-width: 900px){
            position: relative;
            float: right;
            margin-top: 6px;
        }
    }
}


// How to Order Block
.orderBlock{

    @include padding-all(70px, null, 70px, null);
    border-top: 20px solid #e7e7e8;
    position: relative; text-align: center;

    .panelHeadings{

        h2{
            font-weight: $medium;            
        }
    }

    .fiveRow{
        li{
            width: calc( 20% - (16px));
            margin-right: 20px;

            &:nth-child(5n+5) {margin-right: 0px;}            
        }

        @media screen and (max-width: 1150px){

            li{
                width: 33.333%;
                width: calc( 33.333% - (20px));
                margin-right: 20px;

                &:nth-child(5n+5) {margin-right: 20px !important;}
                &:nth-of-type(5n+1) {clear: inherit;}                    

                &:nth-child(3n+3) {margin-right: 0px;}
                &:nth-of-type(3n+1) {clear: left;}
            }
        } 

        @media screen and (max-width: 992px){

            margin: 0 50px;

            li{
                width: 50%; float: left;
                width: calc( 50% - (15px));                

                &:nth-child(4n+4) {margin-right: 30px;}
                &:nth-of-type(4n+1) {clear: inherit;}

                &:nth-child(3n+3) {margin-right: 30px;}
                &:nth-of-type(3n+1) {clear: inherit;}

                &:nth-child(2n+2) {margin-right: 30px;}
                &:nth-of-type(2n+1) {clear: left;}
            }
        }

        @media screen and (max-width: 600px){

            li{
                width: 100%; float: left;
                width: calc( 100% - (0px));                

                &:nth-child(4n+4) {margin-right: 0px;}
                &:nth-of-type(4n+1) {clear: inherit;}

                &:nth-child(3n+3) {margin-right: 0px;}
                &:nth-of-type(3n+1) {clear: inherit;}

                &:nth-child(2n+2) {margin-right: 0px;}
                &:nth-of-type(2n+1) {clear: inherit;}
            }
        }

        .flickity-prev-next-button{
            width: 50px;
            height: 50px;
            border-radius: 0;
            //background: rgba(20,61,96,0.8);
            @include padding(0px);

            svg{
                display: none !important;
            }            
        }

        .flickity-prev-next-button.previous { 
            left: -60px;

            &:after{
                content: "\f053"; font-family: 'FontAwesome';
                position: absolute;
                @include middle; left: 43%;
                color: $mblue; font-size: 40px;
            }
        }

        .flickity-prev-next-button.next{ 
            right: -55px;

            &:after{
                content: "\f054"; font-family: 'FontAwesome';
                position: absolute;
                @include middle; right: 30%;
                color: $mblue; font-size: 40px;
            }
        } 
    }

    .groupCta{

        margin-bottom: 30px;

        &.threeRow{

            @media screen and (max-width: 992px){

                margin: 0 50px;
            }

            li{
                width: calc(33.333% - 14px);
                margin-right: 20px;
                margin-bottom: 20px;

                &:nth-child(3n+3) {margin-right: 0px;}

                @media screen and (max-width: 1000px){

                    width: 50%;
                    width: calc( 50% - (10px));
                    
                    &:nth-of-type(3n+1) {clear: inherit;}

                    &:nth-child(2n+2) {margin-right: 0px;}
                    &:nth-of-type(2n+1) {clear: left;}                      
                }

                @media screen and (max-width: 768px){

                    width: 100%;
                    width: calc( 100% - (0px));                        

                    &:nth-child(3n+3) {margin-right: 0px;}
                    &:nth-of-type(3n+1) {clear: inherit;}

                    &:nth-child(2n+2) {margin-right: 0px;}
                    &:nth-of-type(2n+1) {clear: inherit;}           
                }
            }         
        }
    }

    .blocks{
        @include padding-all(50px, null, 50px, null); 

        @media screen and (max-width: 1150px){
            @include padding-all(40px, null, 30px, null);
        }   

        .head{
            background-color: $primary-color;
            color: $w; font-size: 20px;
            padding: 14px 0px;
            font-weight: $regular;
            text-align: center; 
            position: relative;

            h4{
                font-size: 20px;
                margin-top: 10px;
            }

            i{
                background-color: $w;
                border: 4px solid $primary-color;
                border-radius: 100%;
                width: 80px; height: 80px;                
                text-align: center;
                display: inline-block;
                margin-top: -50px; 
                padding: 13px 15px;
            } 

            span{
                background-color: $w; color: $primary-color;
                width: 50px; height: 50px;
                position: absolute; left: 0; top: 0;
                display: inline-block; z-index: 1;
                overflow: hidden; text-align: left;
                font-weight: $medium; font-size: 30px;

                -webkit-border-bottom-right-radius: 60px;
                -moz-border-radius-bottomright: 60px;
                border-bottom-right-radius: 60px;            
            }     
        }

        .info{
            background-color: $lgrey;
            padding: 20px 15px;        
            min-height: 240px;
            width: 100%;
            line-height: 24px;
            text-align: left;
            font-size: 16px;
        }

        .cta2{
            margin-bottom: 15px;
            padding-right: 65px;
        }
    }    
}


// Case Studies Block
.caseStudiesBlock{
    @include padding-all(70px, null, 70px, null);

    .inner{
        max-width: 1425px;
    }

    .image{
        overflow: hidden;
    }

    .content{
        @include padding-all(40px, 140px, 40px, 40px);

        @include media (992px){
            @include padding-all(0px, 0px, 20px, 0px);
        }

        h3{
            padding-bottom: 30px;
            font-size: 32px;
        }
        h5{
            font-weight: $medium;
            margin-bottom: 40px;
            line-height: 30px;
        }
        p{
            line-height: 24px;
        }
    }

    .iletSystems-gallery{
        margin-top: 50px;

        li{  
            width: calc( 25% - (15px));
            margin-right: 20px;          
            margin-bottom: 20px; 

            &:nth-child(4n+4) {margin-right: 0px;}
            &:nth-of-type(4n+1) {clear: left;} 

            @include media(992px){

                width: 33.333%; float: left;
                width: calc(33.333% - 22.5px);

                &:nth-child(3n+3)   {margin-right: 0px;}
                &:nth-of-type(3n+1) {clear: left;}

                &:nth-child(4n+4) {margin-right: 20px;}
                &:nth-of-type(4n+1) {clear: inherit;} 
            }

            @include media(768px){

                width: 50%; float: left;
                width: calc(50% - (20px));

                &:nth-child(2n+2)   {margin-right: 0px;}
                &:nth-of-type(2n+1) {clear: left;}

                &:nth-child(3n+3)   {margin-right: 20px;}
                &:nth-of-type(3n+1) {clear: inherit;}

                &:nth-child(4n+4) {margin-right: 20px;}
                &:nth-of-type(4n+1) {clear: inherit;} 
            }
        }         
    }

    .history-block{
        width: 130%;
        padding-bottom: 30px;
        @include selfClearing;         

        .header{
            //background-color: $mblue;
            padding: 10px 40px;
            color: $w;
            font-weight: $medium;
            font-size: 30px;
            position: relative;
            height: 55px;

            span{
                position: relative;
                z-index: 1;
            }

            &:before {
                width: 100%;
                height: 55px;
                background-color: $mblue;
                content: '';
                position: absolute;                
                left: 0px; top: 0; z-index: 0;
            }
            &:after {
                width: 0px;
                height: 0px;
                border-left: transparent solid 30px;
                border-right: transparent solid 30px;
                border-bottom: 55px solid $primary-color;

                content: '';
                position: absolute;
                pointer-events: none;
                left: -30px; top: 0; z-index: 0;        
            }
        }

        @include media (992px){
            width: 100%;

            .header{
                text-align: right; 
                margin-left: 30px;

                &:before {
                    width: 98.8%;                    
                }               
            }
        }

        .leftBlock{

            .content{
                @include padding-all(30px, 140px, 0px, 40px);
            } 

            @include media (1150px){
                width: 67%;
                padding: 0px;
            } 

            @include media (992px){
                width: 100%;

                .content{
                    @include padding-all(30px, 0px, 0px, 0px);
                } 
            }
        }

        .rightBlock{

            @include media (1150px){
                width: 40%;
                margin-left: -7%;
            }
            @include media (992px){
                width: 100%;
                margin-left: 0;
            }          

            padding-top: 20px;

            .historySlider{
                .flickity-page-dots{
                    width: auto;
                    bottom: 15px;        
                    left: 15px;

                    .dot {
                        width: 15px;
                        height: 15px;
                        margin: 0 3px;
                        background: $trans;            
                        opacity: 1;            
                        border: 2px solid $w;

                        &.is-selected {                
                            background: $w;
                        }
                    }

                    @media screen and (max-width: 970px){
                        bottom: 20px;
                        left: 20px;

                        .dot {
                            width: 20px;
                            height: 20px;
                            margin: 0 5px;                            
                            border: 1px solid $w;                            
                        }
                    }
                }
            }            
        }
        
    }

    .leftBlock{
        float: left;
        width: 77%;
        background-color: $w;

        @include media(992px){

            .image{
                margin-bottom: 20px;
            }
        }
    }
    .rightBlock{
        float: left;
        width: 30%;
        padding-top: 40px;
        margin-left: -7%;
    }

    @include media(992px){
        .leftBlock{
            width: 100%;
            @include padding-all(30px, 20px, 30px, 20px);
        }
        .rightBlock{
            width: 100%;
            padding-top: 30px;
            margin-left: 0;

            .request-quote{
                margin-bottom:0px !important;
            }
        }
    }
}


// KEY FACTS Block
.key-facts{
    margin-bottom: 30px;
    position: relative;

    h3{
        background-color: $dblue;
        color: $w;
        font-size: 20px;
        @include padding-all(20px, 25px, 20px, 25px);
        opacity: 0.9;
    }
    ul{
        background-color: $primary-color;
        @include margin(0px);
        @include padding-all(15px, 0px, 15px, 0px);
        list-style: none;
        color: $w;

        li{
            @include padding-all(15px, 10px, 15px, 25px);
            font-size: 22px;
            font-weight: $medium;
            letter-spacing: $font-space1;

            a{
                color: $w;

                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}


// Case Studies & Team Page Block
.listBlock{

    .inner{
        max-width: 1425px;
    }

    ul{
        @include padding(0);
        @include margin (0);
        list-style: none;

        li{
            margin-bottom: 30px;
            position: relative;
            background-color: $w;            
            @include selfClearing;                
            @include transition-all(.3s); position: relative;

            &:last-child{
                margin-bottom: 0px;
            }              

            .image{
                overflow: hidden; 
                width: 24%; 
                float: left;                    

                img{
                    width: 100%; 
                    height: 100%;
                }                                        
            }

            .content{
                width: 76%; 
                float: left;
                font-weight: $light;
                @include padding-all(30px, 10px, 70px, 40px);

                h2{                        
                    font-size: 36px;
                    font-weight: $medium;
                    color: $primary-color; 
                    padding-bottom: 15px;                      
                }

                h4{ padding-bottom: 15px; }

                .details{

                    p:first-child{margin-top: 0px;}

                    p{                    
                        font-weight: $regular;
                        line-height: 30px;
                        font-size: 20px;
                    }
                }
                
            } 

            .cta{

                position: absolute;
                right: 10px; bottom: 10px;

                &:hover{
                    color: $w; 
                    background-color: $ddblue;                            

                    &:after{
                        border-bottom: 50px solid $ddblue;
                    }
                }
            }

            @media screen and (max-width: 1024px){
                .image{ width: 35%;}
                .content{ width: 65%;}
            } 

            @media screen and (max-width: 768px){
                .image{
                    width: 100%;
                    max-height: 400px;
                }
                .content{
                    width: 100%;
                    @include padding-all(20px, 20px, 70px, 20px);
                }
            } 

            @media screen and (max-width: 500px){
                .image{ max-height: 350px; }                    
            }                         
        }
    }
}


// Privacy Policy Block
.privacyPolicy{
    @include padding-all(70px, null, 70px, null);

    h3{        
        @include padding-all(20px, null, null, null);
    }
    h5{
        font-weight: $medium;
        margin-bottom: 10px;
        line-height: 26px;
    }
    p{
        line-height: 26px;
    }
    ul{
        padding: 0px;

        li{
            @include padding-all(5px, null, 5px, null);
            margin-left: 20px;
        }
    }
    
    .inner{
        max-width: 1425px;
        background-color: $w;
        @include padding-all(30px, 30px, 30px, 30px);
    }
}



//Contact Form

#frmContact{

    @include selfClearing
    
    .form-group{
        padding: 0 10px 10px 0;

        .form-control {
            width: 100%;
            border: 0px;
            outline: none;
            min-height: 50px;
            padding: 10px;
            color: $secondary-color;
            border:1px solid $lgrey;
        }

        .form-check{
            position: absolute;
            z-index: 9;
        }
        .check-label{
            padding-left: 26px;
            float: left;
            font-size: $bodyMobile;
            color: $mgrey;
        }
    }

    .cta{
        text-align: center;
        float: right;
        margin-right: 10px;
        margin-top: 20px;

        &:hover{
            color: $w; 
            background-color: $dblue;            

            &:after{
                border-bottom: 50px solid $dblue;
            }
        }
    }
}

.error{
    border:1px solid $red !important;
}





















